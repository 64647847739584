import { getNow } from './get-now/now.util';

export const cookieUtils = {
    setCookie: (name: string, value: string, days: number, domain: string): void => {
        let expires = '';
        if (days) {
            const date = getNow();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/' + '; domain=' + domain;
    },
    getCookie: (name: string): string | undefined => {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return undefined;
    },
    eraseCookie: (name: string, domain: string): void => {
        document.cookie =
            name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC; Max-Age=-99999999;  path=/' + '; domain=' + domain + ';';
    },
};
